@tailwind base;
@tailwind components;
@tailwind utilities;

input::file-selector-button {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

input:disabled::file-selector-button {
    cursor: not-allowed;
}


::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #dae1e8;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    background-color: #b7c3ce;
}
